import * as React from "react"
import { Link } from "gatsby"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
        <div className="flex flex-col items-center">
          <img src="/images/404.svg" className="h-80 w-80"/>
          <h1 className="font-bold text-3xl mt-3">Oops.. Page not found</h1>
          <p className="text-gray-400 mt-3">We can’t seem to find the page you are looking for</p>
          <Link to="/" className="mt-9 text-primary">Go back home</Link>.
        </div>
    </main>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
